import aboutpic from "./components/Access/new_face.png"

const header = {
  // all the properties are optional - can be left empty or deleted
  homepage: '',
  title: 'DS',
}

const about = {
  photo:aboutpic,
  // all the properties are optional - can be left empty or deleted
  name: 'DHRUV SHAH',
  role: 'Full Stack Developer',
  description:
    'Second Year Computer Science and Business Administration Student at the University of Waterloo and Wilfrid Laurier University',
  resume: 'https://drive.google.com/file/d/1xap86NIKbn1c3Yp03XJng7KF9YE1wHQk/view?usp=sharing',
  social: {
    linkedin: 'http://www.linkedin.com/in/DhruvShah38',
    github: 'https://github.com/shahman27',
  },
}

const projects = [
  // projects can be added an removed
  // if there are no projects, Projects section won't show up
  {
    name: 'Quiz Web App',
    description:
      'A state of the art quiz web app built with React.js including authentication and private routing features creating a secure platform. Using Firebase as a database to manage current users and to host the web app as a live website.',
    stack: ['nodejs', 'React.js', 'JavaScript','HTML', 'CSS'],
    sourceCode: 'https://github.com/shahman27/Quiz-App',
    livePreview: 'https://auth-quiz-dev.web.app/login',
  },
  {
    name: 'NY-Times Wordle Clone',
    description:
      'Command-line implementation of popular NY-Times game, Wordle developed in C. Completed with an algorithm to determine possible solutions given certain guesses. Created an algorithm that filters a dictionary database to determine possible valid words to solve the game efficiently.',
    stack: ['C'],
    sourceCode: 'https://github.com/shahman27/NY-Times-Wordle-Clone',
    livePreview: '',
  },
  {
    name: 'Minesweeper',
    description:
      'Developed a version of popular game, Minesweeper that enables users to play the game in the terminal. Features include flagging bombs, checking if squares are valid or if it is a mine as well as counting adjacent mines.',
    stack: ['C'],
    sourceCode: 'https://github.com/shahman27/Minesweeper',
    livePreview: '',
  },
]

const skills = [
  // skills can be added or removed
  // if there are no skills, Skills section won't show up
  'HTML',
  'CSS',
  'JavaScript',
  'C',
  'Firebase',
  'Linux',
  'React',
  'Python',
  'Git',
]

const contact = {
  // email is optional - if left empty Contact section won't show up
  email: 'dhruvshah38@gmail.com',
}

export { header, about, projects, skills, contact }
